import { Injectable,} from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import {DatePipe, formatDate} from '@angular/common'
import * as moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class PdfService {
  pdfMake: any;
  public sec: any;
  public min: any;
  public hour: any;
  public tmp: any;
  public debut: any;
  public fin: any;


  constructor(public datepipe: DatePipe,
  ) {
  }

  async loadPdfMaker() {
    if (!this.pdfMake) {
      const pdfMakeModule = await import('pdfmake/build/pdfmake');
      const pdfFontsModule = await import('pdfmake/build/vfs_fonts');
      this.pdfMake = pdfMakeModule.default;
      this.pdfMake.vfs = pdfFontsModule.default.pdfMake.vfs;
    }
  }

  async generatePdf(listAnomalies_bac, type, locale) {
    await this.loadPdfMaker();

    function buildTableBody(data, columns) {
      var body = [];

      body.push(columns);

      data.forEach(function (element) {
        var dataRow = [];
        columns.forEach(function (column) {
          if (column === 'Entreprise') {
            const entreprise = element.entrepriseId != null ? element.entreprise.name : '';
            dataRow.push(entreprise);
          }
          if (column === 'chauffeur') {
            const chauffeur = element.driverId != null ? element.driver.lastname + ' ' + element.driver.firstname : '';
            dataRow.push(chauffeur);
          }
          if (column === 'circuit') {
            const circuit = element.circuitId != null ? element.circuit.name : '';
            dataRow.push(circuit);
          }
          if (column === 'latlong') {
            const latLong = element.latitude != null && element.longitude != null ?{ text: element.latitude + ',' + element.longitude, link: `https://www.google.com/maps?q=${element.latitude},${element.longitude}` } : '';
           // const latLong = element.latitude != null && element.longitude != null ? element.latitude + ',' + element.longitude : '';
            dataRow.push(latLong);
          }
          if (column === 'date') {
            // const latest_date = element.createdAt.toString().substring(0,10);
            // const latest_time = formatDate(latest_date, 'HH:mm:ss', locale);
            // let alldatetime = latest_date + " " + latest_time;
            //8888888888888888888888888nesoriko aloha en attendant88888888888888888888888888888888888888888888
            // var tttt = moment(element.createdAt).subtract(6, 'h');
            //888888888888888888888888888888888888888888888888888888888888888888888
            const tttt1 = moment(element.createdAt).format("YYYY-MM-DD HH:mm");
            dataRow.push(tttt1);
          }
          if (column === 'description') {
            const description = element.anomalyId != null ? element.anomaly.description : '';
            dataRow.push(description);
          }});
        body.push(dataRow);
      });
      return body;
    }

    function table(data, columns) {
      return {
        table: {
          headerRows: 1,
          body: buildTableBody(data, columns)
        }
      };
    }

    var dd = {
      content: [
        { text: 'Rapport Anomalies', style: 'header' },
        table(listAnomalies_bac, ['Entreprise', 'chauffeur', 'circuit', 'latlong', 'date', 'description'])
      ]
    };
    if(type ==='affiche'){
      this.pdfMake.createPdf(dd).open();
    }
    if(type ==='download'){
      this.pdfMake.createPdf(dd).download();
    }
    if(type ==='print'){
      this.pdfMake.createPdf(dd).print();
    }
  }


  async generatePdfRapportj(list, debut, fin,type, locale) {
    await this.loadPdfMaker();
    function buildTableBody(data, columns) {
      var body = [];
      body.push(columns);

      data.forEach(function (element) {
        var dataRow = [];
        columns.forEach(function (column) {
          if (column === 'Entreprise') {
            const entreprise = element.entrepriseId != null ? element.entreprise.name : '';
            dataRow.push(entreprise);
          }

          if (column === 'DateHeure') {
            const tttt1 = moment(element.createdAt).format("YYYY-MM-DD HH:mm:ss");
            dataRow.push(tttt1);
          }

          if (column === 'Vehicule') {
            const Vehicule = element.bomId != null ? element.bom.immatriculation : '';
            dataRow.push(Vehicule);
          }

          if (column === 'Chauffeur') {
            const Chauffeur = element.driverId != null ? element.driver.lastname +' ' + element.driver.firstname:'';
            dataRow.push(Chauffeur);
          }

          if (column === 'Km initiale') {
            const Kilometrageinitial = element.Kilometrageinitial != null ? element.Kilometrageinitial : '';
            dataRow.push(Kilometrageinitial);
          }

          if (column === 'Km finale') {
            const Kilometragefinal = element.Kilometragefinal != null ? element.Kilometragefinal : '';
            dataRow.push(Kilometragefinal);
          }

          if (column === 'Km parcourue') {
            const Kilometrageparcourue = element.Kilometragefinal != null ?  element.Kilometragefinal-element.Kilometrageinitial  : 0;
            dataRow.push(Kilometrageparcourue);
          }

          if (column === 'Commentaire') {
            const Commentaire = element.comment != null ? element.comment : '';
            dataRow.push(Commentaire);
          }

        });
        body.push(dataRow);
      });
      return body;
    }
    function table(data, columns) {
      return {
        table: {
          headerRows: 1,
          body: buildTableBody(data, columns)
        }
      };
    }

    let dd = {
      content: [
        { text: 'Rapport journalier', style: 'header' },
        table(list, ['Entreprise', 'DateHeure',  'Vehicule', 'Chauffeur', 'Km initiale', 'Km finale', 'Km parcourue', 'Commentaire'])
      ]
    };
    if(type ==='affiche'){
      this.pdfMake.createPdf(dd).open();
    }
    if(type ==='download'){
      this.pdfMake.createPdf(dd).download();
    }
    if(type ==='print'){
      this.pdfMake.createPdf(dd).print();
    }
  }

  async generatePdfCollect(list, debut, fin,type, locale) {
    await this.loadPdfMaker();
    function buildTableBody(data, columns) {
      var body = [];
      body.push(columns);

      data.forEach(function (element) {
        var dataRow = [];
        columns.forEach(function (column) {
          if (column === 'Entreprise') {
            const entreprise = element.collect.entrepriseId != null ? element.collect.entreprise.name : '';
            dataRow.push(entreprise);
          }

          if (column === 'Date') {
            const tttt1 = moment(element.createdAt).format("YYYY-MM-DD");
            dataRow.push(tttt1);
          }

          if (column === 'Circuit') {
            const Circuit = element.circuitId != null ? element.circuit.name : '';
            dataRow.push(Circuit);
          }
          if (column === 'Vehicule') {
            const Vehicule = element.bomId != null ? element.bom.immatriculation : '';
            dataRow.push(Vehicule);
          }

          if (column === 'Chauffeur') {
            const Chauffeur = element.driverId != null ? element.driver.lastname +' ' + element.driver.firstname:'';
            dataRow.push(Chauffeur);
          }
          if (column === 'Ripeur 1') {
            const Ripeur1 = element.lastnameripeur1 != null ? element.collect.lastnameripeur1+' '+element.collect.firstnameripeur1 :'';
            dataRow.push(Ripeur1);
          }
          if (column === 'Ripeur 2') {
            const Ripeur2 = element.lastnameripeur2 != null ? element.collect.lastnameripeur2+' '+element.collect.firstnameripeur2 :'';
            dataRow.push(Ripeur2);
          }
          if (column === 'Ripeur 3') {
            const Ripeur3 = element.lastnameripeur3 != null ? element.collect.lastnameripeur3+' '+element.collect.firstnameripeur3 :'';
            dataRow.push(Ripeur3);
          }
          if (column === 'Localisation début') {
            const Localisationdebut = (element.collect.latitudedebut != null && element.collect.longitudedebut != null ) ?{ text: element.collect.latitudedebut + ',' + element.collect.longitudedebut, link: `https://www.google.com/maps?q=${element.collect.latitudedebut},${element.collect.longitudedebut}` } : '';

         //   const Localisationdebut =(element.collect.latitudedebut != null && element.collect.longitudedebut != null )? ( element.collect.latitudedebut+', '+element.collect.longitudedebut) : '';
            dataRow.push(Localisationdebut);
          }
          if (column === 'Localisation fin') {
            const Localisationfin = (element.collect.latitudefin != null && element.collect.longitudefin != null ) ?{ text: element.collect.latitudefin + ',' + element.collect.longitudefin, link: `https://www.google.com/maps?q=${element.collect.latitudefin},${element.collect.latitudefin}` } : '';

        //    const Localisationfin =(element.collect.latitudefin != null && element.collect.longitudefin != null )? ( element.collect.latitudefin+', '+element.collect.longitudefin) : '';
            dataRow.push(Localisationfin);
          }
          if (column === 'Km initiale') {
            const Kminitiale = (element.collect.kilometrageInitialecoll) ? element.collect.kilometrageInitialecoll :'';
            dataRow.push(Kminitiale);
          }
          if (column === 'Km finale') {
            const Kmfinale = (element.collect.kilometrageFinalecoll) ? element.collect.kilometrageFinalecoll :'';
            dataRow.push(Kmfinale);
          }

          if (column === 'Km parcourue') {
            const Kilometrageparcourue = element.collect.kilometrageFinalecoll != null ? element.collect.kilometrageFinalecoll - element.collect.Kilometrageinitial  : 0;
            dataRow.push(Kilometrageparcourue);
          }

          if (column === 'Début') {
            if(element.createdAtBegin !== null){
              const time = moment(element.createdAtBegin).format("HH:mm:ss");
              dataRow.push(time);
            }
          }
          if (column === 'Fin') {
            if(element.createdAtEnd !== null){
              const time1 = moment(element.createdAtEnd).format("HH:mm:ss");
              dataRow.push(time1);
            }else{
              dataRow.push("----");
            }
          }
          if (column === 'NbrHeure') {
            const NbrHeure =( element.collect.hourEnd != null && element.collect.hourEnd != undefined )? dateDiff(debut+' '+element.collect.hourBegin, debut+' '+element.collect.hourEnd) : 'en cours';
            dataRow.push(NbrHeure);
          }

        });
        body.push(dataRow);
      });
      return body;
    }
    function table(data, columns) {
      return {
        table: {
          headerRows: 1,
          body: buildTableBody(data, columns)
        }
      };
    }

    function dateDiff(date1, date2){
      date1 = new Date(date1);
      date2 = new Date(date2);
      let tmp:any;
      let tmp_:any;
      tmp_ = date2 - date1;
      tmp = Math.floor(tmp_/1000);
      let sec:any;
      sec = tmp % 60;
      tmp = Math.floor((tmp - sec)/60);
      let min:any;
      min = tmp % 60;
      tmp = Math.floor((tmp - min)/60);
      let hour:any;
      let hour_:any;

      hour = tmp % 24;
      if(hour<10){
        hour_ = '0' + hour
      }
      if(min<10){
        min = '0' + min
      }
      if(sec<10){
        sec = '0' + sec
      }
      return hour +':' + min+':'+ sec;
    }

    let dd = {
      content: [
        { text: 'Rapport collecte', style: 'header' },
        table(list, ['Entreprise', 'Date',  'Circuit', 'Vehicule', 'Chauffeur', 'Ripeur 1', 'Ripeur 2', 'Ripeur 3', 'Localisation début', 'Localisation fin', 'Km initiale', 'Km finale', 'Km parcourue', 'Début' ,'Fin', 'NbrHeure' ])
  ]
    };
    if(type ==='affiche'){
      this.pdfMake.createPdf(dd).open();
    }
    if(type ==='download'){
      this.pdfMake.createPdf(dd).download();
    }
    if(type ==='print'){
      this.pdfMake.createPdf(dd).print();
    }
  }


  async generatePdfTonage(list, debut, fin,type, locale) {
    await this.loadPdfMaker();
    function buildTableBody(data, columns) {
      var body = [];
      body.push(columns);

      data.forEach(function (element) {
        var dataRow = [];
        columns.forEach(function (column) {
          if (column === 'Entreprise') {
            const entreprise = element.entrepriseId != null ? element.entreprise.name : '';
            dataRow.push(entreprise);
          }

          if (column === 'DateHeure') {
            const tttt1 = moment(element.createdAt).format("YYYY-MM-DD HH:mm:ss");
            dataRow.push(tttt1);
          }
          if (column === 'Vehicule') {
            const Vehicule = element.bomId != null ? element.bom.immatriculation : '';
            dataRow.push(Vehicule);
          }
          if (column === 'Circuit') {
            const Circuit = element.circuitId != null ? element.circuit.name : '';
            dataRow.push(Circuit);
          }
          if (column === 'Chauffeur') {
            const Chauffeur = element.driverId != null ? element.driver.lastname +' ' + element.driver.firstname:'';
            dataRow.push(Chauffeur);
          }

          if (column === 'Tonnage') {
            const Tonnage = element.tonnage != null ? element.tonnage : '';
            dataRow.push(Tonnage);
          }
          if (column === 'Vidage debut') {
            const Tonnage = (element.vidage != null  && element.vidage.length > 0 && element.vidage[0].hourBegin !== null) ? moment(element.vidage[0].hourBegin).format("YYYY-MM-DD HH:mm:ss")  : '';
            dataRow.push(Tonnage);
          }
          if (column === 'Vidage fin') {
            const Tonnage = (element.vidage != null  && element.vidage.length > 0 && element.vidage[0].hourEnd !== null) ? moment(element.vidage[0].hourEnd).format("YYYY-MM-DD HH:mm:ss")  : '';
            dataRow.push(Tonnage);
          }



        });
        body.push(dataRow);
      });
      return body;
    }
    function table(data, columns) {
      return {
        table: {
          headerRows: 1,
          body: buildTableBody(data, columns)
        }
      };
    }

    let dd = {
      content: [
        { text: 'Rapport tonnage', style: 'header' },
        table(list, ['Entreprise', 'DateHeure',  'Vehicule', 'Circuit', 'Chauffeur', 'Tonnage', 'Vidage debut', 'Vidage fin'])
      ]
    };
    if(type ==='affiche'){
      this.pdfMake.createPdf(dd).open();
    }
    if(type ==='download'){
      this.pdfMake.createPdf(dd).download();
    }
    if(type ==='print'){
      this.pdfMake.createPdf(dd).print();
    }
  }


  async generatePdfRapportBac(list, debut, fin,type, locale) {
    await this.loadPdfMaker();
    function buildTableBody(data, columns) {
      var body = [];
      body.push(columns);

      data.forEach(function (element) {
        var dataRow = [];
        columns.forEach(function (column) {
          if (column === 'Entreprise') {
            const entreprise = element.entrepriseId != null ? element.entreprise.name : '----';
            dataRow.push(entreprise);
          }

          if (column === 'Heure / Date') {
            const tttt1 = moment(element.heuredateformated).format("YYYY-MM-DD HH:mm:ss");
            dataRow.push(tttt1);
          }
          if (column === 'BOM') {
            const bom = element.bom != null ? element.bom : '----';
            dataRow.push(bom);
          }

          if (column === 'Conducteur/N° Circuit') {
            const Vehicule = element.conducteurcircuit != null ? element.conducteurcircuit : '----';
            dataRow.push(Vehicule);
          }
          if (column === 'Numéro de Bac') {
            const Circuit = element.numerobac != null ? element.numerobac : '----';
            dataRow.push(Circuit);
          }
          if (column === 'Lieu de Bac collecté') {
            const Chauffeur = element.lieubac != null ? element.lieubac:'----';
            dataRow.push(Chauffeur);
          }

          if (column === 'Coordonées GPS Bac') {
            const Tonnage = (element.gps != null  ) ?{ text: element.gps, link: `https://www.google.com/maps?q=${ element.gps.split(',')[0] },${ element.gps.split(',')[1] }` } : '';

            //const Tonnage = element.gps != null ? element.gps : '';
            dataRow.push(Tonnage);
          }

        });
        body.push(dataRow);
      });
      return body;
    }
    function table(data, columns) {
      return {
        table: {
          headerRows: 1,
          body: buildTableBody(data, columns)
        }
      };
    }

    let dd = {
      content: [
        { text: 'Rapport levée bac', style: 'header' },
        table(list, ['Entreprise', 'Heure / Date', 'BOM', 'Conducteur/N° Circuit', 'Numéro de Bac', 'Lieu de Bac collecté', 'Coordonées GPS Bac'])
      ]
    };
    if(type ==='affiche'){
      this.pdfMake.createPdf(dd).open();
    }
    if(type ==='download'){
      this.pdfMake.createPdf(dd).download();
    }
    if(type ==='print'){
      this.pdfMake.createPdf(dd).print();
    }
  }

  async generatePdfVidage(list, debut, fin,type, locale) {
    await this.loadPdfMaker();
    function buildTableBody(data, columns) {
      var body = [];
      body.push(columns);

      data.forEach(function (element) {
        var dataRow = [];
        columns.forEach(function (column) {
          if (column === 'Entreprise') {
            const entreprise = element.entrepriseId != null ? element.entreprise.name : '';
            dataRow.push(entreprise);
          }

          if (column === 'DateHeure') {
            const tttt1 = moment(element.createdAt).format("YYYY-MM-DD HH:mm:ss");
            dataRow.push(tttt1);
          }

          if (column === 'Circuit') {
            const Circuit = element.circuitId != null ? element.circuit.name : '';
            dataRow.push(Circuit);
          }

          if (column === 'Vehicule') {
            const Vehicule = element.bomId != null ? element.bom.immatriculation : '';
            dataRow.push(Vehicule);
          }

          if (column === 'Chauffeur') {
            const Chauffeur = element.driverId != null ? element.driver.lastname +' ' + element.driver.firstname:'';
            dataRow.push(Chauffeur);
          }

          if (column === 'Km initiale') {
            const Kilometrageinitial = element.Kilometrageinitial != null ? element.Kilometrageinitial : '';
            dataRow.push(Kilometrageinitial);
          }

          if (column === 'Km finale') {
            const Kilometragefinal = element.Kilometragefinal != null ? element.Kilometragefinal : '';
            dataRow.push(Kilometragefinal);
          }

          if (column === 'Km parcourue') {
            const Kilometrageparcourue = element.Kilometragefinal != null ?  element.Kilometragefinal-element.Kilometrageinitial  : 0;
            dataRow.push(Kilometrageparcourue);
          }

        });
        body.push(dataRow);
        });
      return body;
    }
    function table(data, columns) {
      return {
        table: {
          headerRows: 1,
          body: buildTableBody(data, columns)
        }
      };
    }

    let dd = {
      content: [
        { text: 'Rapport vidage', style: 'header' },
        table(list, ['Entreprise', 'DateHeure', 'Circuit', 'Vehicule', 'Chauffeur', 'Km initiale', 'Km finale', 'Km parcourue'])
      ]
    };
    if(type ==='affiche'){
      this.pdfMake.createPdf(dd).open();
    }
    if(type ==='download'){
      this.pdfMake.createPdf(dd).download();
    }
    if(type ==='print'){
      this.pdfMake.createPdf(dd).print();
    }
   }

  async generatePdfPointage(listPointing, debut, fin,type, locale) {
    await this.loadPdfMaker();
    function buildTableBody(data, columns) {
      var body = [];

      body.push(columns);
      data.forEach(function (element) {
        var dataRow = [];
        columns.forEach(function (column) {
          if (column === 'Entreprise') {
            const entreprise = element.entrepriseId != null ? element.entreprise.name : '';
            dataRow.push(entreprise);
          }
          if (column === 'Vehicule') {
            const Vehicule = element.bomId != null ? element.bom.immatriculation : '';
            dataRow.push(Vehicule);
          }
          if (column === 'DateHeure') {
            // let latest_date = element.createdAt.toString().substring(0,10);
            // // let latest_time = element.createdAt.toString().substring(11,19);
            // const latest_time = formatDate(element.createdAt, 'HH:mm', locale);
            // let alldatetime = latest_date + " " + latest_time;
            //88888888888888888888888888888idm88888888888888888888888888888888888888888888
            // var tttt = moment(element.createdAt).subtract(6, 'h');
            //8888888888888888888888888888888888888888888888888888888888888888888888888888
            const tttt1 = moment(element.createdAt).format("YYYY-MM-DD HH:mm:ss");
            dataRow.push(tttt1);
          }
          if (column === 'Circuit') {
            const Circuit = element.circuitId != null ? element.circuit.name : '';
            dataRow.push(Circuit);
          }
          if (column === 'Quantite') {
            dataRow.push(element.quality);
          }
          if (column === 'Nom') {
            dataRow.push(element.lastname);
          }
          if (column === 'Prenom') {
            dataRow.push(element.firstname);
          }
          if (column === 'debut') {
            const transform0 = (element.createdAt).toString().substring(0,10);
            if(element.createdAtBegin !== null){
              //88888888888888888888888888888888888888888888888888888888888888888
              // var tttt = moment(element.createdAt).subtract(6, 'h');
              //88888888888888888888888888888888888888888888888888888888888888888
               const time = moment(element.createdAt).format("YYYY-MM-DD HH:mm:ss");
              dataRow.push(time);
            }
            //time = formatDate(transform0transform1, 'HH:mm:ss', locale);
          }
          if (column === 'Fin') {
            if(element.createdAtEnd !== null){
              //888888888888888888888888888idm88888888888888888888888888888888888888888
              // var tttt1 = moment(element.updatedAt).subtract(6, 'h');
              //88888888888888888888888888888888888888888888888888888888888888888888888
              const time1 = moment(element.updatedAt).format("YYYY-MM-DD HH:mm:ss");
              dataRow.push(time1);
            }else{
              dataRow.push("----");
            }
          }
          if (column === 'NbrHeure') {
            const NbrHeure =( element.end != null && element.end != undefined )? dateDiff(debut+' '+element.start, debut+' '+element.end) : 'en cours';
            dataRow.push(NbrHeure);
          }
        });
        body.push(dataRow);
      });
      return body;
    }

    function table(data, columns) {
      return {
        table: {
          headerRows: 1,
          body: buildTableBody(data, columns)
        }
      };
    }

    var dd = {
      content: [
        { text: 'Rapport Pointage', style: 'header' },
        table(listPointing, ['Entreprise', 'Vehicule', 'DateHeure', 'Circuit', 'Quantite', 'Nom', 'Prenom', 'debut', 'Fin', 'NbrHeure'])
      ],
      pageOrientation: 'landscape'
    };

    // function dateDiff(date1, date2) {
    // date1 = new Date(date1);
    // date2 = new Date(date2);
    // let tmp_ = date2 - date1;
    // let tmp = Math.floor(tmp_ / 1000);
    // const sec = tmp % 60;
    // tmp = Math.floor((tmp - sec) / 60);
    // const min = tmp % 60;
    // tmp = Math.floor((tmp - min) / 60);
    // const hour = tmp % 24;
    // return (hour < 10) ? '0' + hour : hour + ':' +(min < 10) ? '0' + min : min + ':' + (sec < 10) ? '0' + sec : sec;
    // }


    function dateDiff(date1, date2){
      date1 = new Date(date1);
      date2 = new Date(date2);
      let tmp:any;
      let tmp_:any;
      tmp_ = date2 - date1;
      tmp = Math.floor(tmp_/1000);
      let sec:any;
      sec = tmp % 60;
      tmp = Math.floor((tmp - sec)/60);
      let min:any;
      min = tmp % 60;
      tmp = Math.floor((tmp - min)/60);
      let hour:any;
      let hour_:any;

      hour = tmp % 24;
      if(hour<10){
        hour_ = '0' + hour
      }
      if(min<10){
        min = '0' + min
      }
      if(sec<10){
        sec = '0' + sec
      }
      return hour +':' + min+':'+ sec;
    }

    if(type ==='affiche'){
      this.pdfMake.createPdf(dd).open();
    }
    if(type ==='download'){
      this.pdfMake.createPdf(dd).download();
    }
    if(type ==='print'){
      this.pdfMake.createPdf(dd).print();
    }
  }

}
