import {Component, OnInit, EventEmitter, ViewChild, OnChanges, Inject, LOCALE_ID} from '@angular/core';
import { CollectService } from '../../services/collect.service';
import { DatatableLanguage } from "../../constant/french-datatable";
import { Subject } from "rxjs";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import {NgxSpinnerService} from "ngx-spinner";
import {formatDate} from "@angular/common";
import * as moment from 'moment';
import {PointingService} from "../../services/pointing.service";
import {PdfService} from "../../services/pdf.service";

@Component({
  selector: 'app-collect',
  templateUrl: './collect.component.html',
  styleUrls: ['./collect.component.css']
})

export class CollectComponent implements OnInit, OnChanges {
  @ViewChild('confirmCheckDeletePlsCollect') confirmCheckDeletePlsCollect: any;
  @ViewChild('confirmDelete') confirmDelete: any;
  @ViewChild('openCart') openCart: any;
  @ViewChild('map', { static: true }) mapElement: any;
  map: google.maps.Map;
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;

  public listCollect: any = [];
  public debut: any;
  public fin: any;
  // option module datatable
  public dtOptions: any = {};
  public dtTigger = new Subject();
  public entrepriseType : any;
  public collectId: any;
  public aujourdui: any;
  public hier: any;
  public debut_semaine: any;
  public fin_semaine: any;
  public debut_mois: any;
  public fin_mois: any;
  public emitData: EventEmitter<any> = new EventEmitter;
  public a: boolean;
  private limit: number = 50;
  public checkActiondemande: any;
  public checkEnableDisablePlusieursCollectArray: any = [];
  public Hdebut: any;
  public Hfin: any;
  public utc: any;

  constructor(
    private collectService: CollectService,
    private pdfService: PdfService,
    private ngbModal: NgbModal,
    private spinner: NgxSpinnerService,
    private pointingService: PointingService,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.a = false;
    this.utc = {typeSigne: 0, offset: 0};
  }

  public date_aujour_d_hui(){
    var ladate = new Date();
    return ladate.getFullYear()+'-'+(ladate.getMonth()+1)+'-'+ladate.getDate()
  }

  ngOnInit(): void {
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    //Get utc
    const utc = localStorage.getItem("utc");
    if (utc !== null) {
      this.utc = JSON.parse(utc);
    }
    this.aujourdui = this.pointingService.date_aujour_d_hui();
    this.hier = this.pointingService.less_day_date(this.date_aujour_d_hui(),1);
    // this.debut = this.pointingService.date_aujour_d_hui();
    // this.fin = this.pointingService.date_aujour_d_hui();
    this.traitement_semaine();
    this.traitement_mois();
    this.a = false;
    this.dtOptions = {
      language: DatatableLanguage.datatableFrench,
      lengthMenu:[50,70,100],
      "order": [[ 0, 'desc' ]],
      "autoWidth": true,
      "responsive": true,
    };
    this.entrepriseType = localStorage.getItem('entrepriseType');
    if(this.entrepriseType == "1"){
      this.collectService.getCollects(this.limit)
        .subscribe((res: any) => {
          this.listCollect = res.data;
          this.spinner.hide();
          this.dtTigger.next()
        })
    }else{
      let body = {
        entrepriseId: localStorage.getItem('entrepriseId')
      };
      this.collectService.getCollectsByEntreprise(body,this.limit)
        .subscribe((res: any) => {
          this.listCollect = res.data;
          if(body.entrepriseId === "12"){
            this.Hdebut = '00:00';
            this.Hfin = '23:59'
            this.debut = this.pointingService.date_aujour_d_hui();
            this.fin = this.pointingService.date_aujour_d_hui();
          }else if(body.entrepriseId === "10"){
            this.Hdebut = '17:00';
            this.Hfin = '16:00'
            this.debut= moment.utc(this.pointingService.date_lendemain()).format("YYYY-MM-DD");
            this.fin = this.pointingService.date_aujour_d_hui();
        }
          this.spinner.hide();
          this.dtTigger.next()
        })
    }
  }


  public traitement_semaine(){
    var ladate=new Date();
    var position = ladate.getDay();
    this.debut_semaine =  this.pointingService.less_day_date(this.date_aujour_d_hui(), position);
    this.fin_semaine = this.pointingService.add_day_date(this.debut_semaine,6);
  }

  public traitement_mois(){
    var mois = this.aujourdui.slice(5,7);
    var annees = this.aujourdui.slice(0,4);
    var nbrjrsmoi = new Date(parseInt(annees), parseInt(mois), 1);
    this.debut_mois = this.aujourdui.slice(0,8)+'01';
    this.fin_mois = this.aujourdui.slice(0,8)+(nbrjrsmoi.getUTCDate());
  }

  ngOnChanges(): void {
    this.loadCollect()
  }

  /**
   * loadCollect
   */
  public loadCollect(): void {
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      if(this.entrepriseType == "1"){
        this.collectService.getCollects(this.limit)
          .subscribe((res: any) => {
            this.listCollect = res.data;
            this.spinner.hide();
            this.dtTigger.next()
          })
      }else{
        let body = {
          entrepriseId: localStorage.getItem('entrepriseId')
        };
        this.collectService.getCollectsByEntreprise(body,this.limit)
          .subscribe((res: any) => {
            this.listCollect = res.data;
            this.spinner.hide();
            this.dtTigger.next()
          })
      }
    })
  }
  public aa() {
    this.a = true
  }

  bb() {
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.a = false;
    this.collectService.getCollects(this.limit)
      .subscribe((res: any) => {
        this.listCollect = res.data;
        this.spinner.hide();
      })
  }


  /**
   * beforeDelete
   */
  public beforeDelete(itemId: any) {
    this.collectId = itemId;
    this.emitData.emit(itemId);
    this.ngbModal.open(this.confirmDelete)
  }


  /**
   * deleteCollects
   */
  public deleteCollects() {
    let body = {
      id: this.collectId
    };
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.collectService.deleteCollects(body)
      .subscribe((res: any) => {
        this.loadCollect();
        this.resetField();
        this.spinner.hide();
      })
  }


  public supprimerPlusieursCollecte() {
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.collectService.supprimerPlusieursCollects(this.checkEnableDisablePlusieursCollectArray)
      .subscribe((res: any) => {
        this.loadCollect();
        this.resetField();
        this.spinner.hide()
      })
  }

  public openModaPourSupprimerPlusieursCollect() {
    this.checkActiondemande = 'add';
    this.ngbModal.open(this.confirmCheckDeletePlsCollect)
  }

  public checkEnableDisablePlusieursCollect(id,event){
    if(event.target.checked) {
      let item = 0;
      for(let i = 0; i<this.checkEnableDisablePlusieursCollectArray.length; i++){
        if(this.checkEnableDisablePlusieursCollectArray[i] == id){
          item++;
        }
      }
      if(item === 0 ){
        this.checkEnableDisablePlusieursCollectArray.push(id);
      }
    }else{
      this.checkEnableDisablePlusieursCollectArray = this.checkEnableDisablePlusieursCollectArray.filter(function (item) {
        return item !== id;
      });
    }
  }

  public dateDiffbycreatedAt(date1, date2){
    if(date1 == undefined || date1 == null)
      return '----';
    if (date2 == undefined || date2 == null)
      return '----';

    let d1 = new Date(date1);
    let d2 = new Date(date2);

    // @ts-ignore
    let difference = Math.abs(d1 - d2); //to get absolute value
    //calculate for each one
    let Days = Math.floor(difference / ( 1000 * 60 * 60 * 24 ));
    let Hours = Math.floor((difference / ( 1000 * 60 * 60 )) % 24);
    let Mins = Math.floor((difference / ( 1000 * 60 )) % 60);
    let Seconds = Math.floor((difference / ( 1000 )) % 60);
    return `${Hours.toString().padStart(2, '0')}:${Mins.toString().padStart(2, '0')}:${Seconds.toString().padStart(2, '0')}`;
  }

  public openMap(latitude,long) {
    this.ngbModal.open(this.openCart);
    const mapProperties = {
      center: new google.maps.LatLng(latitude, long),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    //this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
    this.map = new google.maps.Map(document.getElementById("map"), mapProperties);
    const marker = new google.maps.Marker({
      position: new google.maps.LatLng(latitude, long),
      map: this.map,
      title: 'markers'
    });
    google.maps.event.trigger(this.map, "resize")
    //
  }


  public getBy2date(start, end){
    let start_ = start;
    let end_ = end;
    if(this.Hdebut != undefined || this.Hfin != undefined){
      start_ = start + " " + this.Hdebut + ":00";
      end_ = end + " " + this.Hfin + ":00";
    }
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      if(this.entrepriseType == "1"){
        let body = {
          startDate: start_,
          endDate: end_
        };
        this.collectService.getCollectBetweenTwoDates(body)
          .subscribe((res: any) => {
            this.listCollect = res.data;
            this.spinner.hide();
            this.dtTigger.next()
          })
      }else{
        let bodyE = {
          entrepriseId: localStorage.getItem('entrepriseId'),
          startDate: start_,
          endDate: end_
        };
        this.collectService.getCollectBetweenTwoDatesByEntreprise(bodyE)
          .subscribe((res: any) => {
            this.listCollect = res.data;
            this.spinner.hide();
            this.dtTigger.next()
          })
      }
    })
  }
  public dateDiff(date1, date2){
    date1 = new Date(date1);
    date2 = new Date(date2);
    const dd = moment.utc(moment(date2,"DD/MM/YYYY HH:mm:ss").diff(moment(date1,"DD/MM/YYYY HH:mm:ss"))).format("HH:mm:ss");
    return dd
  }

  public dateDiff1(date1, date2){
    date1 = new Date(date1);
    date2 = new Date(date2);
    let tmp:any;
    let tmp_:any;
    tmp_ = date2 - date1;
    tmp = Math.floor(tmp_/1000);
    let sec:any;
    sec = tmp % 60;
    tmp = Math.floor((tmp - sec)/60);
    let min:any;
    min = tmp % 60;
    tmp = Math.floor((tmp - min)/60);
    let hour:any;
    let hour_:any;

    hour = tmp % 24;
    if(hour<10){
      hour_ = '0' + hour
    }
    if(min<10){
      min = '0' + min
    }
    if(sec<10){
      sec = '0' + sec
    }
    return hour +':' + min+':'+ sec;
  }
  // GEstion field data
  public resetField() {
    this.ngbModal.dismissAll()
    // this.id = '';
  }



  generateExcel(){
    let id_E = "";
    if(this.entrepriseType == "1"){

    }else{
      id_E = localStorage.getItem('entrepriseId')
    }
    let listCollectExcel = [];
    if(this.listCollect.length > 0) {
      this.spinner.show();
      setTimeout(()=>this.spinner.hide(),10000);
      for (let i = 0; i < this.listCollect.length; i++) {
        const item = this.listCollect[i];
        let transform0 = null;
        if(item.collect !== null && item.collect !== undefined) {
          if (item.collect.createdAt !== null && item.collect.createdAt !== undefined) {
            transform0 = (item.collect.createdAt).toString().substring(0, 10);
            // const transform1 = formatDate(item.collect.createdAt, 'HH:mm:ss', this.locale);
            // item.collect.createdAt = transform0 + ' ' + transform1;
            let tttt;
            if (this.utc.typeSigne == 0) {
              tttt = moment(item.collect.createdAt).subtract(this.utc.offset, 'h');
            } else {
              tttt = moment(item.collect.createdAt).add(this.utc.offset, 'h');
            }

            item.collect.createdAt = tttt;
          }
          if(item.collect.hourBegin !== null){
             const hb0 = transform0 + ' ' + item.collect.hourBegin;
            // const hb = formatDate(hb0, 'HH:mm:ss', this.locale);
            // item.hourBegin = hb;
            let tttt1;
            if (this.utc.typeSigne == 0) {
              tttt1 = moment(hb0).subtract(this.utc.offset, 'h');
            } else {
              tttt1 = moment(hb0).add(this.utc.offset, 'h');
            }
            item.hourBegin =  moment(tttt1).format("HH:mm:ss");
          }else{
            item.hourBegin = "00:00:00";
          }

          if(item.collect.hourEnd !== null) {
             const he0 = transform0 + ' ' + item.collect.hourEnd;
            // const he = formatDate(he0, 'HH:mm:ss', this.locale);
            // item.hourEnd = he;
            let tttt2;
            if (this.utc.typeSigne == 0) {
              tttt2 = moment(he0).subtract(this.utc.offset, 'h');
            } else {
              tttt2 = moment(he0).add(this.utc.offset, 'h');
            }
            item.hourEnd =  moment(tttt2).format("HH:mm:ss");
          }else{
            item.hourEnd = "00:00:00";
          }

          listCollectExcel.push(item);
        }
      }
      let body = {
        listCollectExcel: listCollectExcel,
        id_E: id_E
      };

      this.collectService.excel(body)
        .subscribe((res: any) => {
          if (res.status == 200) {
            console.log("resulatat",res);
            const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
            a.href = res.url;
            document.body.appendChild(a);
            a.click();
            this.loadCollect();
            this.resetField();
            this.spinner.hide();
            document.body.removeChild(a);
          }
        })
    }
  }


  generatePdf(type) {
    this.pdfService.generatePdfCollect(this.listCollect,this.debut,this.fin,type, this.locale);
  }


}
