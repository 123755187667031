import {Component, OnInit, EventEmitter, ViewChild, OnChanges, Inject, LOCALE_ID} from '@angular/core';
import { TonnageService } from '../../services/tonnage.service';
import { DatatableLanguage } from "../../constant/french-datatable";
import { Subject } from "rxjs";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import {NgxSpinnerService} from "ngx-spinner";
import {formatDate} from "@angular/common";
import * as moment from 'moment';
import {PointingService} from "../../services/pointing.service";
import {PdfService} from "../../services/pdf.service";


@Component({
  selector: 'app-tonnage',
  templateUrl: './tonnage.component.html',
  styleUrls: ['./tonnage.component.css']
})

export class TonnageComponent implements OnInit, OnChanges {
  @ViewChild('confirmCheckDeletePlsTonnage') confirmCheckDeletePlsTonnage: any;
  @ViewChild('confirmDelete') confirmDelete: any;
    @ViewChild('openCart') openCart: any;
  @ViewChild('map', { static: true }) mapElement: any;
  map: google.maps.Map;
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;

  public listTonnage: any = [];
  public debut: any;
  public Hdebut: any;
  public fin: any;
  public Hfin: any;
  // option module datatable
  public dtOptions: any = {};
  public dtTigger = new Subject();
  public entrepriseType : any;
  public tonnageId: any;
  public aujourdui: any;
  public hier: any;
  public debut_semaine: any;
  public fin_semaine: any;
  public debut_mois: any;
  public fin_mois: any;
  public emitData: EventEmitter<any> = new EventEmitter;
  public a: boolean;
  private limit: number = 50;
  public checkActiondemande: any;
  public checkEnableDisablePlusieursTonnageArray: any = [];
  public utc: any;

  constructor(
    private tonnageService: TonnageService,
    private pdfService: PdfService,
    private ngbModal: NgbModal,
    private spinner: NgxSpinnerService,
    private pointingService: PointingService,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.a = false;
    this.utc = {typeSigne: 0, offset: 0};
  }

  public date_aujour_d_hui(){
    var ladate = new Date();
    return ladate.getFullYear()+'-'+(ladate.getMonth()+1)+'-'+ladate.getDate()
  }

  ngOnInit(): void {
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    //Get utc
    const utc = localStorage.getItem("utc");
    if (utc !== null) {
      this.utc = JSON.parse(utc);
    }
    this.aujourdui = this.pointingService.date_aujour_d_hui();
    this.hier = this.pointingService.less_day_date(this.date_aujour_d_hui(),1);
    //this.debut = this.pointingService.date_aujour_d_hui();
    //this.fin = this.pointingService.date_aujour_d_hui();
    this.traitement_semaine();
    this.traitement_mois();
    this.a = false;
    this.dtOptions = {
      language: DatatableLanguage.datatableFrench,
      lengthMenu:[50,70,100],
      "order": [[ 0, 'desc' ]],
      "autoWidth": true,
      "responsive": true,
    };
    this.entrepriseType = localStorage.getItem('entrepriseType');
    if(this.entrepriseType == "1"){
      this.tonnageService.getTonnage(this.limit)
      .subscribe((res: any) => {
        this.listTonnage = res.data;
        this.spinner.hide();
        this.dtTigger.next()
      })
     }else{
      let body = {
        entrepriseId: localStorage.getItem('entrepriseId')
      };
      this.tonnageService.getTonnageByEntreprise(body,this.limit)
        .subscribe((res: any) => {
          this.listTonnage = res.data;
           this.spinner.hide();
          if(body.entrepriseId === "12"){
            this.Hdebut = '00:00';
            this.Hfin = '23:59'
            this.debut = this.pointingService.date_aujour_d_hui();
            this.fin = this.pointingService.date_aujour_d_hui();
          }else if(body.entrepriseId === "10"){
            this.Hdebut = '17:00';
            this.Hfin = '16:00'
            this.debut= moment.utc(this.pointingService.date_lendemain()).format("yyyy-MM-DD");
            this.fin = this.pointingService.date_aujour_d_hui();

        }
          this.dtTigger.next()
        })
     }
  }



  public traitement_semaine(){
    var ladate=new Date();
    var position = ladate.getDay();
    this.debut_semaine =  this.pointingService.less_day_date(this.date_aujour_d_hui(), position);
    this.fin_semaine = this.pointingService.add_day_date(this.debut_semaine,6);
  }

  //public traitement_mois(){
   // var mois = this.aujourdui.slice(5,7);
   // var annees = this.aujourdui.slice(0,4);
   // var nbrjrsmoi = new Date(parseInt(annees), parseInt(mois), 1);
    // this.debut_mois = this.aujourdui.slice(0,8)+'01';
  //  const annee = moment(new Date()).format("YYYY");
   // const month = moment(new Date()).format("MM");
   //  this.debut_mois = annee + '-' + month + '01';
   //  this.fin_mois = annee + '-' + month + '30';
    // this.fin_mois = this.aujourdui.slice(0,8)+(nbrjrsmoi.getUTCDate());
  //}
    public traitement_mois(){
    var mois = this.aujourdui.slice(5,7);
    var annees = this.aujourdui.slice(0,4);
    var nbrjrsmoi = new Date(parseInt(annees), parseInt(mois), 1);
    this.debut_mois = this.aujourdui.slice(0,8)+'01';
    this.fin_mois = this.aujourdui.slice(0,8)+(nbrjrsmoi.getUTCDate());
  }

  ngOnChanges(): void {
    this.loadTonnage()
  }

  /**
   * loadTonnage
   */
  public loadTonnage(): void {
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      if(this.entrepriseType == "1"){
        this.tonnageService.getTonnage(this.limit)
        .subscribe((res: any) => {
          this.listTonnage = res.data;
          this.spinner.hide();
          this.dtTigger.next()
        })
       }else{
        let body = {
          entrepriseId: localStorage.getItem('entrepriseId')
        };
        this.tonnageService.getTonnageByEntreprise(body,this.limit)
          .subscribe((res: any) => {
            this.listTonnage = res.data;
            this.spinner.hide();
            this.dtTigger.next()
          })
       }
    })
  }
  public aa() {
    this.a = true
   }

    bb() {
      this.spinner.show();
      setTimeout(()=>this.spinner.hide(),10000);
      this.a = false;
      this.tonnageService.getTonnage(this.limit)
      .subscribe((res: any) => {
        this.listTonnage = res.data;
        this.spinner.hide();
      })
    }


   /**
   * beforeDelete
   */
  public beforeDelete(itemId: any) {
    this.tonnageId = itemId;
    this.emitData.emit(itemId);
    this.ngbModal.open(this.confirmDelete)
  }


  /**
   * deleteTonnage
   */
  public deleteTonnage() {
    let body = {
      id: this.tonnageId
    };
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.tonnageService.deleteTonnage(body)
      .subscribe((res: any) => {
        this.loadTonnage();
        this.resetField();
        this.spinner.hide();
      })
  }


  public supprimerPlusieursTonnage() {
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.tonnageService.supprimerPlusieursTonnage(this.checkEnableDisablePlusieursTonnageArray)
      .subscribe((res: any) => {
        this.loadTonnage();
        this.resetField();
        this.spinner.hide()
      })
  }

  public openModaPourSupprimerPlusieursTonnage() {
    this.checkActiondemande = 'add';
    this.ngbModal.open(this.confirmCheckDeletePlsTonnage)
  }

  public checkEnableDisablePlusieursTonnage(id,event){
    if(event.target.checked) {
      let item = 0;
      for(let i = 0; i<this.checkEnableDisablePlusieursTonnageArray.length; i++){
        if(this.checkEnableDisablePlusieursTonnageArray[i] == id){
          item++;
        }
      }
      if(item === 0 ){
        this.checkEnableDisablePlusieursTonnageArray.push(id);
      }
    }else{
      this.checkEnableDisablePlusieursTonnageArray = this.checkEnableDisablePlusieursTonnageArray.filter(function (item) {
        return item !== id;
      });
    }
  }


  public afficherBy2Date(start, end){
    let start_ = start;
    let end_ = end;
    if(this.Hdebut != undefined || this.Hfin != undefined){
      start_ = start + " " + this.Hdebut + ":00";
      end_ = end + " " + this.Hfin + ":00";

    }
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      if(this.entrepriseType == "1"){
        let body = {

          startDate: start_,
          endDate: end_
        };
        this.tonnageService.getTonnageBetweenTwoDates(body)
          .subscribe((res: any) => {
            this.listTonnage = res.data;
            this.spinner.hide();
            this.dtTigger.next()
          })
      }else{
        let bodyE = {
          entrepriseId: localStorage.getItem('entrepriseId'),
          startDate: start_,
          endDate: end_
        };
         console.log("date", bodyE.startDate, bodyE.endDate)
        this.tonnageService.getTonnageBetweenTwoDatesByEntreprise(bodyE)
          .subscribe((res: any) => {
            this.listTonnage = res.data;
            this.spinner.hide();
            this.dtTigger.next()
          })

      }
    })
  }


  public getBy2date(start, end){
    let start_ = start;
    let end_ = end;
    console.log("date",start, end)
    if(this.Hdebut != undefined || this.Hfin != undefined){
      start_ = start + " " + this.Hdebut + ":00";
      end_ = end + " " + this.Hfin + ":00";
    }
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      if(this.entrepriseType == "1"){
        let body = {
          startDate: start_,
          endDate: end_
        };
        this.tonnageService.getTonnageBetweenTwoDates(body)
          .subscribe((res: any) => {
            this.listTonnage = res.data;
            this.spinner.hide();
            this.dtTigger.next()
          })
      }else{
        let bodyE = {
          entrepriseId: localStorage.getItem('entrepriseId'),
          startDate: start_,
          endDate: end_
        };
        if(bodyE.entrepriseId=="3"){
        let bodyE = {
          entrepriseId: localStorage.getItem('entrepriseId'),
          startDate: moment(start).format("YYYY-MM-DD HH:mm:ss"),
          endDate: moment(end).format("YYYY-MM-DD HH:mm:ss"),
        };
         console.log("date", bodyE.startDate, bodyE.endDate)
        this.tonnageService.getTonnageBetweenTwoDatesByEntreprise(bodyE)
          .subscribe((res: any) => {
            this.listTonnage = res.data;
            this.spinner.hide();
            this.dtTigger.next()
          });
        }else{
        this.tonnageService.getTonnageBetweenTwoDatesByEntreprise(bodyE)
          .subscribe((res: any) => {
            this.listTonnage = res.data;
            this.spinner.hide();
            this.dtTigger.next()
          });
        };

      };
    })
  }




  // GEstion field data
  public resetField() {
    this.ngbModal.dismissAll()
    // this.id = '';
  }




  generateExcel(){
    let id_E = "";
    if(this.entrepriseType == "1"){

    }else{
      id_E = localStorage.getItem('entrepriseId')
    }
    let listTonnageExcel = [];
    if(this.listTonnage.length > 0) {
      this.spinner.show();
      setTimeout(()=>this.spinner.hide(),10000);
      for (let i = 0; i < this.listTonnage.length; i++) {
        const item = this.listTonnage[i];
        let transform0 = null;
        if (item.createdAt !== null && item.createdAt !== undefined) {
          if (item.createdAt !== null && item.createdAt !== undefined) {
             transform0 = (item.createdAt).toString().substring(0, 10);
            // const transform1 = formatDate(item.createdAt, 'HH:mm:ss', this.locale);
            // item.createdAt = transform0 + ' ' + transform1;
            let tttt;
            if (this.utc.typeSigne == 0) {
              tttt = moment(item.createdAt).subtract(this.utc.offset, 'h');
            } else {
              tttt = moment(item.createdAt).add(this.utc.offset, 'h');
            }
            item.createdAt = tttt;
          }
          if(item.vidage != null && item.vidage.length > 0 && item.vidage[0].hourBegin !== null){
             const hb0 = transform0 + ' ' + item.vidage[0].hourBegin;
            // const hb = formatDate(hb0, 'HH:mm:ss', this.locale);
            // item.hourBegin = hb;
            let tttt1;
            if (this.utc.typeSigne == 0) {
              tttt1 = moment(hb0).subtract(this.utc.offset, 'h');
            } else {
              tttt1 = moment(hb0).add(this.utc.offset, 'h');
            }
            item.hourBegin = moment(tttt1).format("HH:mm:ss");
          }else{
            item.hourBegin = "00:00:00";
          }

          if(item.vidage != null && item.vidage.length > 0 && item.vidage[0].hourEnd !== null){
            const he0 = transform0 + ' ' + item.vidage[0].hourEnd;
            // const he = formatDate(he0, 'HH:mm:ss', this.locale);
            // item.hourEnd = he;
            let tttt2;
            if (this.utc.typeSigne == 0) {
              tttt2 = moment(he0).subtract(this.utc.offset, 'h');
            } else {
              tttt2 = moment(he0).add(this.utc.offset, 'h');
            }
            item.hourEnd = moment(tttt2).format("HH:mm:ss");
          }else{
            item.hourEnd = "00:00:00";
          }
      }
        listTonnageExcel.push(item);
      }
      let body = {
        listTonnageExcel: listTonnageExcel,
        id_E: id_E
      };

      this.tonnageService.excel(body)
        .subscribe((res: any) => {
          if (res.status == 200) {
            const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
            a.href = res.url;
            document.body.appendChild(a);
            a.click();
            this.loadTonnage();
            this.resetField();
            this.spinner.hide();
            document.body.removeChild(a);
          }
        })
    }
  }

  generatePdf(type) {
    this.pdfService.generatePdfTonage(this.listTonnage,this.debut,this.fin,type, this.locale);
  }


}
