import {
    Component,
    OnInit,
    EventEmitter,
    ViewChild,
    OnChanges,
    OnDestroy,
    ElementRef,
    LOCALE_ID,
    Inject
} from '@angular/core';
import {CollectService} from '../../services/collect.service';
import {DatatableLanguage} from "../../constant/french-datatable";
import {Subject} from "rxjs";
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DataTableDirective} from 'angular-datatables';
import {NgxSpinnerService} from "ngx-spinner";
import {EmailService} from "../../services/email.service";
import {EntrepriseService} from "../../services/entreprise.service";
import {environment} from "../../../environments/environment";
import {DatePipe, formatDate} from "@angular/common";
import {FormGroup, FormBuilder} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import * as moment from 'moment';
import {PointingService} from "../../services/pointing.service";

@Component({
    selector: 'app-affectation',
    templateUrl: './affectation.component.html',
    styleUrls: ['./affectation.component.css']
})

export class AffectationComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('confirmCheckDeletePlsCollect') confirmCheckDeletePlsAffectation: any;
    @ViewChild('confirmDelete') confirmDelete: any;
    @ViewChild('confirmDeleteEmail') confirmDeleteEmail: any;
    @ViewChild('openCart') openCart: any;
    @ViewChild('actionEmail') actionEmail: any;
    @ViewChild('map', {static: true}) mapElement: any;
    map: google.maps.Map;
    @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
    @ViewChild('UploadFileInput', {static: false}) uploadFileInput: ElementRef;
    fileUploadForm: FormGroup;
    fileInputLabel: string;

    public urlimage: any = "";
    public logo: any = "";
    public listEntreprise: any = [];
    public listAffectation: any = [];
    public listEmail: any = [];
    public debut: any = new Date();
    public fin: any = new Date();
    public Hfin: any;
    public Hdebut: any;
    // option module datatable
    public dtOptions: any = {};
    public dtOptions1: any = {};
    public dtTigger = new Subject();
    public entrepriseType: any;
    public affectationId: any;
    public emailId: any;
    public emailName: any;
    public objetEmail: string = "";
    public idEntreprise: any;
    public aujourdui: any;
    public hier: any;
    public debut_semaine: any;
    public fin_semaine: any;
    public debut_mois: any;
    public fin_mois: any;
    public emitData: EventEmitter<any> = new EventEmitter;
    public a: boolean;
    private limit: number = 50;
    public checkAction: any;
    public utc = null;

    constructor(
        private httpClient: HttpClient,
        private formBuilder: FormBuilder,
        private affectationService: CollectService,
        private emailService: EmailService,
        private ngbModal: NgbModal,
        private spinner: NgxSpinnerService,
        private entrepriseService: EntrepriseService,
        private pointingService: PointingService,
        @Inject(LOCALE_ID) private locale: string
    ) {
        this.a = false;
        this.utc = {typeSigne: 0, offset: 0};
    }

    public date_aujour_d_hui() {
        var ladate = new Date();
        return ladate.getFullYear() + '-' + (ladate.getMonth() + 1) + '-' + ladate.getDate()
    }

    ngOnInit(): void {
        this.spinner.show();
        setTimeout(() => this.spinner.hide(), 10000);
        //Get utc
        const utc = localStorage.getItem("utc");
        if (utc !== null) {
            this.utc = JSON.parse(utc);
        }
        this.aujourdui = this.pointingService.date_aujour_d_hui();
        this.hier = this.pointingService.less_day_date(this.pointingService.date_aujour_d_hui(), 1);
        this.traitement_semaine();
        this.traitement_mois();
        this.a = false;
        this.dtOptions = {
            language: DatatableLanguage.datatableFrench,
            lengthMenu: [50, 70, 100],
            "order": [[0, 'desc']],
            "autoWidth": true,
            "responsive": true,
        };
        this.dtOptions1 = {
            language: DatatableLanguage.datatableFrench,
            lengthMenu: [50, 70, 100],
        };

        this.entrepriseType = localStorage.getItem('entrepriseType');
        if (this.entrepriseType == "1") {
            this.affectationService.getCollects(this.limit)
                .subscribe((res: any) => {
                    this.listAffectation = res.data;
                    this.emailService.getEmail(this.limit)
                        .subscribe((res: any) => {
                            this.listEmail = res.data;
                            this.spinner.hide();
                            this.dtTigger.next()
                        })
                });
        } else {
            let body = {entrepriseId: localStorage.getItem('entrepriseId')};
            this.affectationService.getCollectsByEntreprise(body, this.limit)
                .subscribe((res: any) => {
                    this.listAffectation = res.data;
                    this.emailService.getEmailByEntreprise(body, this.limit)
                        .subscribe((res: any) => {
                            this.listEmail = res.data;
                            this.httpClient.post<any>(environment.api + 'getentreprise', body).subscribe(
                                (responsex) => {
                                    if (responsex.status == 200) {
                                        this.urlimage = environment.api + "images/" + responsex.data.logo;
                                        this.logo = responsex.data.logo;
                                        this.objetEmail = responsex.data.objetEmail;
                                    }
                                    if (body.entrepriseId === "12") {
                                        this.Hdebut = '00:00';
                                        this.Hfin = '23:59'
                                        this.debut = this.pointingService.date_aujour_d_hui();
                                        this.fin = this.pointingService.date_aujour_d_hui();
                                    } else if (body.entrepriseId === "10") {
                                        this.Hdebut = '17:00';
                                        this.Hfin = '16:00'
                                        this.debut = moment.utc(this.pointingService.date_lendemain()).format("yyyy-MM-DD");
                                        this.fin = this.pointingService.date_aujour_d_hui();

                                    }
                                    this.spinner.hide();
                                    this.dtTigger.next();
                                });
                        });
                });
        }

        this.entrepriseService.getEntreprises()
            .subscribe((res: any) => {
                this.listEntreprise = res.data;
            });

        this.fileUploadForm = this.formBuilder.group({
            uploadedImage: [''],
        });

    }

    /**
     * emitDataEmail
     */
    public async emitDataEmail(item: any) {
        this.checkAction = 'update';
        this.emitData.emit(item);
        this.emailId = item.id;
        this.emailName = item.email;
        this.idEntreprise = item.entrepriseId;
        await <any>this.ngbModal.open(this.actionEmail)
    }

    ngOnDestroy(): void {
        this.dtTigger.unsubscribe()
    }

    /**
     * openModal
     */
    public openModal(modal) {
        this.checkAction = 'add';
        this.ngbModal.open(modal);
    }


    public traitement_semaine() {
        var ladate = new Date();
        var position = ladate.getDay();
        this.debut_semaine = this.pointingService.less_day_date(this.date_aujour_d_hui(), position);
        this.fin_semaine = this.pointingService.add_day_date(this.debut_semaine, 6);
    }

    public traitement_mois() {
        var mois = this.aujourdui.slice(5, 7);
        var annees = this.aujourdui.slice(0, 4);
        var nbrjrsmoi = new Date(parseInt(annees), parseInt(mois), 1);
        this.debut_mois = this.aujourdui.slice(0, 8) + '01';
        this.fin_mois = this.aujourdui.slice(0, 8) + (nbrjrsmoi.getUTCDate());
    }

    ngOnChanges(): void {
        this.loadAffectation()
    }

    /**
     * loadCollect
     */
    public loadAffectation(): void {
        this.spinner.show();
        setTimeout(() => this.spinner.hide(), 10000);
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            if (this.entrepriseType == "1") {
                this.affectationService.getCollects(this.limit)
                    .subscribe((res: any) => {
                        this.listAffectation = res.data;

                        this.entrepriseService.getEntreprises()
                            .subscribe((res: any) => {
                                this.listEntreprise = res.data;

                                this.emailService.getEmail(this.limit)
                                    .subscribe((res: any) => {
                                        this.listEmail = res.data;
                                        this.spinner.hide();
                                        this.dtTigger.next()
                                    })
                            });
                    });

            } else {
                let body = {
                    entrepriseId: localStorage.getItem('entrepriseId')
                };
                this.affectationService.getCollectsByEntreprise(body, this.limit)
                    .subscribe((res: any) => {
                        this.listAffectation = res.data;

                        this.emailService.getEmailByEntreprise(body, this.limit)
                            .subscribe((res: any) => {
                                this.listEmail = res.data;
                                this.spinner.hide();
                                this.dtTigger.next()
                            })
                    });
            }
        })
    }

    public aa() {
        this.a = true
    }

    bb() {
        this.spinner.show();
        setTimeout(() => this.spinner.hide(), 10000);
        this.a = false;
        this.affectationService.getCollects(this.limit)
            .subscribe((res: any) => {
                this.listAffectation = res.data;
                this.spinner.hide();
            })
    }


    /**
     * beforeDelete
     */
    public beforeDelete(itemId: any) {
        this.affectationId = itemId;
        this.emitData.emit(itemId);
        this.ngbModal.open(this.confirmDelete);
    }


    public beforeDeleteEmail(itemId: any) {
        this.emailId = itemId;
        this.emitData.emit(itemId);
        this.ngbModal.open(this.confirmDeleteEmail);
    }

    /**
     * deleteAffectation , ts miasa
     */
    public deleteAffectation() {
        let body = {
            id: this.affectationId
        };
        this.spinner.show();
        setTimeout(() => this.spinner.hide(), 10000);
        this.affectationService.deleteCollects(body)
            .subscribe((res: any) => {
                this.loadAffectation();
                this.resetField();
                this.spinner.hide();
            })
    }


    public deleteEmail() {
        let body = {
            id: this.emailId
        };
        this.spinner.show();
        setTimeout(() => this.spinner.hide(), 10000);
        this.emailService.deleteEmail(body)
            .subscribe((res: any) => {
                this.loadAffectation();
                this.resetField();
                this.spinner.hide();
            })
    }


    public envoyerEmailManually() {
        this.spinner.show();
        setTimeout(() => this.spinner.hide(), 10000);
        let id_E = "";
        if (this.entrepriseType == "1") {
            id_E = this.idEntreprise
        } else {
            id_E = localStorage.getItem('entrepriseId')
        }
        let listAffectationEmail = [];

        for (let i = 0; i < this.listAffectation.length; i++) {
            const item = this.listAffectation[i];
            if (item.collect) {
                let transform0 = null;
                try {
                } catch (e) {
                }
                if (item.collect.createdAtBegin !== null && item.collect.createdAtBegin !== undefined) {
                    transform0 = (item.collect.createdAtBegin).toString().substring(0, 10);
                    //bon ts net const transform1 = formatDate(item.collect.createdAtBegin, 'HH:mm', this.locale);
                    //item.collect.createdAtBegin = transform0 + ' ' + transform1 + ':00';
                    let tttt;
                    if (this.utc.typeSigne == 0) {
                        tttt = moment(item.collect.createdAtBegin).subtract(this.utc.offset, 'h');
                    } else {
                        tttt = moment(item.collect.createdAtBegin).add(this.utc.offset, 'h');
                    }
                    // var tttt = moment(item.collect.createdAtBegin);
                    item.collect.createdAtBegin = tttt;
                }
            }
            listAffectationEmail.push(item);
        }
        let body = {
            listAffectation: listAffectationEmail,
            listEmail: this.listEmail,
            id_E: id_E
        };

        this.emailService.envoyerEmailManually(body)
            .subscribe((res: any) => {
                this.loadAffectation();
                this.resetField();
                this.spinner.hide();
            })
    }


    public afficherBy2Date(start, end) {
        let start_ = start;
        let end_ = end;
        if (this.Hdebut != undefined || this.Hfin != undefined) {
            start_ = start + " " + this.Hdebut + ":00";
            end_ = end + " " + this.Hfin + ":00";
        }

        this.spinner.show();
        setTimeout(() => this.spinner.hide(), 10000);
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            if (this.entrepriseType == "1") {
                let body = {
                    startDate: start_,
                    endDate: end_
                };
                this.affectationService.getCollectBetweenTwoDates(body)
                    .subscribe((res: any) => {
                        this.listAffectation = res.data;
                        this.spinner.hide();
                        this.dtTigger.next()
                    })
            } else {
                let bodyE = {
                    entrepriseId: localStorage.getItem('entrepriseId'),
                    startDate: start_,
                    endDate: end_
                };
                console.log("date", bodyE.startDate, bodyE.endDate)
                this.affectationService.getCollectBetweenTwoDatesByEntreprise(bodyE)
                    .subscribe((res: any) => {
                        this.listAffectation = res.data;
                        this.spinner.hide();
                        this.dtTigger.next()
                    });
            }
            ;
        })
    }


    public getBy2date(start, end) {
        let start_ = start;
        let end_ = end;
        if (this.Hdebut != undefined || this.Hfin != undefined) {
            start_ = start + " " + this.Hdebut + ":00";
            end_ = end + " " + this.Hfin + ":00";
        }
        this.spinner.show();
        setTimeout(() => this.spinner.hide(), 10000);
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            if (this.entrepriseType == "1") {
                let body = {
                    startDate: start_,
                    endDate: end_
                };
                this.affectationService.getCollectBetweenTwoDates(body)
                    .subscribe((res: any) => {
                        this.listAffectation = res.data;
                        this.spinner.hide();
                        this.dtTigger.next()
                    })
            } else {
                let bodyE = {
                    entrepriseId: localStorage.getItem('entrepriseId'),
                    startDate: start_,
                    endDate: end_
                };
                if (localStorage.getItem('entrepriseId') == "3") {
                    let bodyE = {
                        entrepriseId: localStorage.getItem('entrepriseId'),
                        startDate: moment.utc(start).format("YYYY-MM-DD HH:mm:ss"),
                        endDate: moment.utc(end).format("YYYY-MM-DD HH:mm:ss"),
                    };
                    console.log("date", bodyE.startDate, bodyE.endDate)
                    this.affectationService.getCollectBetweenTwoDatesByEntreprise(bodyE)
                        .subscribe((res: any) => {
                            this.listAffectation = res.data;
                            this.spinner.hide();
                            this.dtTigger.next()
                        });
                } else {

                    this.affectationService.getCollectBetweenTwoDatesByEntreprise(bodyE)
                        .subscribe((res: any) => {
                            this.listAffectation = res.data;
                            this.spinner.hide();
                            this.dtTigger.next()
                        });
                }

            }
            ;
        })
    }


    /**
     * addEmail
     */
    public addEmail() {
        let id_E = "";
        if (this.entrepriseType == "1") {
            id_E = this.idEntreprise
        } else {
            id_E = localStorage.getItem('entrepriseId')
        }

        let body = {
            email: this.emailName,
            entrepriseId: id_E,
        };
        console.log("ide e", body);
        this.spinner.show();
        setTimeout(() => this.spinner.hide(), 10000);
        this.emailService.addEmail(body)
            .subscribe((res: any) => {
                this.loadAffectation();
                this.resetField();
                this.spinner.hide();
            })
    }

    /**
     * updateBoms
     */
    public updateEmail(): void {
        let id_E = "";
        if (this.entrepriseType == "1") {
            id_E = this.idEntreprise;
        } else {
            id_E = localStorage.getItem('entrepriseId')
        }
        let body = {
            id: this.emailId,
            email: this.emailName,
            entrepriseId: id_E,
        };

        this.spinner.show();
        setTimeout(() => this.spinner.hide(), 10000);
        this.emailService.updateEmail(body)
            .subscribe((res: any) => {
                this.loadAffectation();
                this.resetField();
                this.spinner.hide();
            })
    }

    onFileSelect(event) {
        const file = event.target.files[0];
        this.fileInputLabel = file.name;
        this.fileUploadForm.get('uploadedImage').setValue(file);
    }

    sendFile() {
        // if (!this.fileUploadForm.get('uploadedImage').value) {
        //   alert('Veuillez inserer votre logo!');
        //   return false;
        // }
        if (this.objetEmail.trim() == "") {
            alert("L'objet de l'email ne pourrais être pas vide.");
            return false;
        }
        let id_E = "";
        if (this.entrepriseType == "1") {
            id_E = this.idEntreprise;
        } else {
            id_E = localStorage.getItem('entrepriseId')
        }
        const formData = new FormData();
        formData.append('data', this.fileUploadForm.get('uploadedImage').value);
        //formData.append('body', id_E);
        this.uploadFileInput.nativeElement.value = '';
        if (this.fileUploadForm.get('uploadedImage').value) {
            this.httpClient.post<any>(environment.api + 'upload-image', formData).subscribe(
                (response) => {
                    if (response.status === 200) {
                        this.fileInputLabel = undefined;
                        const dt = {nameFile: response.nameFile, objetEmail: this.objetEmail, entrepriseId: id_E};
                        this.httpClient.post<any>(environment.api + 'insertlogo_indb', dt).subscribe(
                            (responsed) => {
                                this.objetEmail = responsed.data.objetEmail;
                                let body = {entrepriseId: id_E,};
                                this.httpClient.post<any>(environment.api + 'getentreprise', body).subscribe(
                                    (responsex) => {
                                        if (responsex.status == 200) {
                                            this.urlimage = environment.api + "images/" + responsex.data.logo;
                                            this.logo = responsex.data.logo;
                                            this.objetEmail = responsex.data.objetEmail;
                                        }
                                    });
                            });
                    }
                },
                (er) => {
                    //   alert(er.error.error);
                });
        } else {
            let body = {
                entrepriseId: id_E,
                objetEmail: this.objetEmail
            };
            this.httpClient.post<any>(environment.api + 'saveobjetEmail', body).subscribe(
                (responsex) => {
                    console.log('responsex', responsex);
                    if (responsex.status == 200) {
                        this.objetEmail = responsex.data.objetEmail;
                    }
                });
        }
    }


    public dateDiff(date1, date2) {
        date1 = new Date(date1);
        date2 = new Date(date2);
        let tmp: any;
        let tmp_: any;
        tmp_ = date2 - date1;
        tmp = Math.floor(tmp_ / 1000);
        let sec: any;
        sec = tmp % 60;
        tmp = Math.floor((tmp - sec) / 60);
        let min: any;
        min = tmp % 60;
        tmp = Math.floor((tmp - min) / 60);
        let hour: any;
        let hour_: any;

        hour = tmp % 24;
        if (hour < 10) {
            hour_ = '0' + hour
        }
        if (min < 10) {
            min = '0' + min
        }
        if (sec < 10) {
            sec = '0' + sec
        }
        return hour + ':' + min + ':' + sec;
    }

    // GEstion field data
    public resetField() {
        this.ngbModal.dismissAll();
        this.emailName = '';
    }
}
