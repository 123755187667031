import {Component, OnInit, EventEmitter, ViewChild, OnChanges, Inject, LOCALE_ID} from '@angular/core';
import { VidageService } from '../../services/vidage.service';
import { DatatableLanguage } from "../../constant/french-datatable";
import { Subject } from "rxjs";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import {NgxSpinnerService} from "ngx-spinner";
import {formatDate} from "@angular/common";
import * as moment from 'moment';
import {PointingService} from "../../services/pointing.service";
import {PdfService} from "../../services/pdf.service";

@Component({
  selector: 'app-vidage',
  templateUrl: './vidage.component.html',
  styleUrls: ['./vidage.component.css']
})
export class VidageComponent implements OnInit, OnChanges {
  @ViewChild('confirmDelete') confirmDelete: any;
  @ViewChild('confirmCheckDeletePlsRapportjournalier') confirmCheckDeletePlsRapportjournalier: any;
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;

  public listRapportjournalier: any = [];
  // option module datatable
  public dtOptions: any = {};
  public dtTigger = new Subject();
  public entrepriseType : any;
  public rapportjournalierId: any;
  public emitData: EventEmitter<any> = new EventEmitter;
  public debut: any;
  public Hdebut: any;
  public fin: any;
  public Hfin: any;
  public aujourdui: any;
  public hier: any;
  public debut_semaine: any;
  public fin_semaine: any;
  public debut_mois: any;
  public fin_mois: any;
  public sec: any;
  public min: any;
  public hour: any;
  public tmp: any;
  private limit: number = 50;
  public checkActiondemande: any;
  public checkEnableDisablePlusieursRapportjournalierArray: any = [];
  public utc: any;

  constructor(
    private rapportjornalierService: VidageService,
    private ngbModal: NgbModal,
    private pdfService: PdfService,
    private spinner: NgxSpinnerService,
    private pointingService: PointingService,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.utc = {typeSigne: 0, offset: 0};
  }

  ngOnInit(): void {
    //Get utc
    const utc = localStorage.getItem("utc");
    if (utc !== null) {
      this.utc = JSON.parse(utc);
    }
    this.aujourdui = this.pointingService.date_aujour_d_hui();
    this.hier = this.pointingService.less_day_date(this.pointingService.date_aujour_d_hui(),1);
   // this.debut = this.pointingService.date_aujour_d_hui();
    //this.fin = this.pointingService.date_aujour_d_hui();
    this.traitement_semaine();
    this.traitement_mois();
    this.dtOptions = {
      language: DatatableLanguage.datatableFrench,
      lengthMenu:[50,70,100],
      "order": [[ 0, 'desc' ]],
      "responsive": true,
    };
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.entrepriseType = localStorage.getItem('entrepriseType');
    if(this.entrepriseType == "1"){
      this.rapportjornalierService.getRapportjornaliers(this.limit)
      .subscribe((res: any) => {
        this.listRapportjournalier = res.data;
        console.log("liste vidage 888888888888888888888888888888888888", this.listRapportjournalier);
        this.spinner.hide();
        this.dtTigger.next()
      })
     }else{
      let body = {
        entrepriseId: localStorage.getItem('entrepriseId')
      };
      this.rapportjornalierService.getRapportjornalierByEntreprise(body,this.limit)
        .subscribe((res: any) => {
          this.listRapportjournalier = res.data;
          this.spinner.hide();
          if(body.entrepriseId === "12"){
            this.Hdebut = '00:00';
            this.Hfin = '23:59';
            this.debut = this.pointingService.date_aujour_d_hui();
            this.fin = this.pointingService.date_aujour_d_hui();
          }else if(body.entrepriseId === "10"){
            this.Hdebut = '17:00';
            this.Hfin = '16:00'
            this.debut= moment.utc(this.pointingService.date_lendemain()).format("yyyy-MM-DD");
            this.fin = this.pointingService.date_aujour_d_hui();

        }
          this.dtTigger.next()
        })
     }
  }

  ngOnChanges(): void {
    this.loadRapportjornalier()
  }

  /**
   * loadRapportjornalier
   */
  public loadRapportjornalier(): void {
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      if(this.entrepriseType == "1"){
        this.rapportjornalierService.getRapportjornaliers(this.limit)
        .subscribe((res: any) => {
          this.listRapportjournalier = res.data;
          this.spinner.hide();
          this.dtTigger.next()
        })
       }else{
        let body = {
          entrepriseId: localStorage.getItem('entrepriseId')
        };
        this.rapportjornalierService.getRapportjornalierByEntreprise(body,this.limit)
          .subscribe((res: any) => {
            this.listRapportjournalier = res.data;
            this.spinner.hide();
            this.dtTigger.next()
          })
       }
    })
  }


   /**
   * beforeDelete
   */
  public beforeDelete(itemId: any) {
    this.rapportjournalierId = itemId;
    this.emitData.emit(itemId);
    this.ngbModal.open(this.confirmDelete)
  }


  /**
   * deleteRapportjornalier
   */
  public deleteRapportjornalier() {
    let body = {
      id: this.rapportjournalierId
    };
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.rapportjornalierService.deleteRapportjornalier(body)
      .subscribe((res: any) => {
        this.loadRapportjornalier();
        this.resetField();
        this.spinner.hide();
      })
  }



  public supprimerPlusieursRapportjournalier() {
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.rapportjornalierService.supprimerPlusieursRapportjournalier(this.checkEnableDisablePlusieursRapportjournalierArray)
      .subscribe((res: any) => {
        this.loadRapportjornalier();
        this.resetField();
        this.spinner.hide()
      })
  }

  public openModaPourSupprimerPlusieursRapportjournalier() {
    this.checkActiondemande = 'add';
    this.ngbModal.open(this.confirmCheckDeletePlsRapportjournalier)
  }

  public checkEnableDisablePlusieursRapportjournalier(id,event){
    if(event.target.checked) {
      let item = 0;
      for(let i = 0; i<this.checkEnableDisablePlusieursRapportjournalierArray.length; i++){
        if(this.checkEnableDisablePlusieursRapportjournalierArray[i] == id){
          item++;
        }
      }
      if(item === 0 ){
        this.checkEnableDisablePlusieursRapportjournalierArray.push(id);
      }
    }else{
      this.checkEnableDisablePlusieursRapportjournalierArray = this.checkEnableDisablePlusieursRapportjournalierArray.filter(function (item) {
        return item !== id;
      });
    }
  }


  public traitement_semaine(){
    var ladate=new Date();
    var position = ladate.getDay();
    this.debut_semaine =  this.pointingService.less_day_date(this.pointingService.date_aujour_d_hui(), position);
    this.fin_semaine = this.pointingService.add_day_date(this.debut_semaine,6);
  }

  public traitement_mois(){
    var mois = this.aujourdui.slice(5,7);
    var annees = this.aujourdui.slice(0,4);
    var nbrjrsmoi = new Date(parseInt(annees), parseInt(mois), 1);
    this.debut_mois = this.aujourdui.slice(0,8)+'01';
    this.fin_mois = this.aujourdui.slice(0,8)+(nbrjrsmoi.getUTCDate());
  }

  public afficherBy2Date(start, end){
    let start_ = start;
    let end_ = end;
    if(this.Hdebut != undefined || this.Hfin != undefined){
      start_ = start + " " + this.Hdebut + ":00";
      end_ = end + " " + this.Hfin + ":00";
    }
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      if(this.entrepriseType == "1"){
        let body = {
          startDate: start_,
          endDate: end_
        };
        this.rapportjornalierService.getBetweenTwoDates(body)
          .subscribe((res: any) => {
            this.listRapportjournalier = res.data;
            this.spinner.hide();
            console.log('listDriver', this.listRapportjournalier);
            this.dtTigger.next()
          })
      }else{
        let bodyE = {
          entrepriseId: localStorage.getItem('entrepriseId'),
          startDate: start_,
          endDate: end_
        };
        console.log("date", bodyE)
        this.rapportjornalierService.getBetweenTwoDatesByEntreprise(bodyE)
          .subscribe((res: any) => {
            this.listRapportjournalier = res.data;
            this.spinner.hide();
            console.log('listDriver', this.listRapportjournalier);
            this.dtTigger.next()
          })
      }
    })
  }



  public getBy2date(start, end){
    let start_ = start;
    let end_ = end;
    if(this.Hdebut != undefined || this.Hfin != undefined){
      start_ = start + " " + this.Hdebut + ":00";
      end_ = end + " " + this.Hfin + ":00";
    }
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      if(this.entrepriseType == "1"){
        let body = {
          startDate: start_,
          endDate: end_
        };
        this.rapportjornalierService.getBetweenTwoDates(body)
          .subscribe((res: any) => {
            this.listRapportjournalier = res.data;
            this.spinner.hide();
            console.log('listDriver', this.listRapportjournalier);
            this.dtTigger.next()
          })
      }else{
        if(localStorage.getItem('entrepriseId')=="3"){
        let bodyE = {
          entrepriseId: localStorage.getItem('entrepriseId'),
          startDate: moment.utc(start).format("YYYY-MM-DD HH:mm:ss"),
          endDate: moment.utc(end).format("YYYY-MM-DD HH:mm:ss"),
        };
        console.log("date", bodyE.startDate, bodyE.endDate)
         this.rapportjornalierService.getBetweenTwoDatesByEntreprise(bodyE)
          .subscribe((res: any) => {
            this.listRapportjournalier = res.data;
            this.spinner.hide();
            console.log('listDriver', this.listRapportjournalier);
            this.dtTigger.next()
          });
        }else{
         let bodyE = {
          entrepriseId: localStorage.getItem('entrepriseId'),
          startDate: start_,
          endDate: end_
        };
        this.rapportjornalierService.getBetweenTwoDatesByEntreprise(bodyE)
          .subscribe((res: any) => {
            this.listRapportjournalier = res.data;
            this.spinner.hide();
            console.log('listDriver', this.listRapportjournalier);
            this.dtTigger.next()
          });
        }
      }
    })
  }



  generatePdf(type) {
    this.pdfService.generatePdfVidage(this.listRapportjournalier,this.debut,this.fin,type, this.locale);
  }



  generateExcel(){
    let id_E = "";
    if(this.entrepriseType == "1"){

    }else{
      id_E = localStorage.getItem('entrepriseId')
    }
    let listVidageExcel = [];
    if(this.listRapportjournalier.length > 0) {
      this.spinner.show();
      setTimeout(()=>this.spinner.hide(),10000);
      for (let i = 0; i < this.listRapportjournalier.length; i++) {
        const item = this.listRapportjournalier[i];
        let transform0 = null;
        if (item.createdAt !== null && item.createdAt !== undefined) {
           transform0 = (item.createdAt).toString().substring(0, 10);
          // const transform1 = formatDate(item.createdAt, 'HH:mm:ss', this.locale);
          // item.createdAt = transform0 + ' ' + transform1;
          let tttt;
          if (this.utc.typeSigne == 0) {
            tttt = moment(item.createdAt).subtract(this.utc.offset, 'h');
          } else {
            tttt = moment(item.createdAt).add(this.utc.offset, 'h');
          }
          item.createdAt = tttt;
        }
        const hb0 = transform0 + ' ' + item.hourBegin;
        // const hb = formatDate(hb0 , 'HH:mm:ss', this.locale);
        // item.hourBegin = hb;
        let tttt1;
        if (this.utc.typeSigne == 0) {
          tttt1 = moment(hb0).subtract(this.utc.offset, 'h');
        } else {
          tttt1 = moment(hb0).add(this.utc.offset, 'h');
        }
        item.hourBegin = moment(tttt1).format("HH:mm:ss");


        const he0 = transform0 + ' ' + item.hourEnd;
        // const he = formatDate(he0 , 'HH:mm:ss', this.locale);
        // item.hourEnd = he;
        let tttt2;
        if (this.utc.typeSigne == 0) {
          tttt2 = moment(he0).subtract(this.utc.offset, 'h');
        } else {
          tttt2 = moment(he0).add(this.utc.offset, 'h');
        }
        item.hourEnd = moment(tttt2).format("HH:mm:ss");


        listVidageExcel.push(item);
      }
      let body = {
        listVidageExcel: listVidageExcel,
        id_E: id_E
      };

      this.rapportjornalierService.excel(body)
        .subscribe((res: any) => {
          if (res.status == 200) {
            const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
            a.href = res.url;
            document.body.appendChild(a);
            a.click();
            this.loadRapportjornalier();
            this.resetField();
            this.spinner.hide();
            document.body.removeChild(a);
          }
        })
    }
  }

  // GEstion field data
  public resetField() {
    this.ngbModal.dismissAll()
  }
}
