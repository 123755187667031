<div  class="limiter">
  <div  class="row justify-content-center m-t-50">
    <div  class="col-md-7 col-lg-5">
      <div class="login-wrap p-4 p-md-5">
        <div  class="icon d-flex align-items-center justify-content-center">
          <img src="../../../../assets/images/poubelle.jpg" style="width: 100%; height: 100%;">
        </div>
        <h3 class="text-center mb-4" style="color: #4bc119;">APP COLLECT</h3>
        <form class="login-form validate-form">
          <div class="wrap-input100 validate-input" data-validate="username is required">
            <input class="input100" type="text" name="username" [(ngModel)]="username"
                   placeholder="Login">
            <span class="focus-input100"></span>
            <span class="label-input100">
                    <i class="fas fa-user"
                       style="margin-left: -2px;font-size: medium;margin-right: 15px; float: right; margin-top: -10px"></i>
                </span>
          </div>


          <div class="wrap-input100 validate-input" data-validate="Password is required">
                    <input class="input100" type="password" name="password" [(ngModel)]="password"
                        placeholder="Mot de passe">
                    <span class="focus-input100"></span>
                    <span class="label-input100">
                        <i class="fas fa-key"
                            style="margin-left: -2px;font-size: medium;margin-right: 15px; float: right; margin-top: -10px"></i>
                    </span>
                </div>
                <div class="help-block"><span class="error">{{help_block}}</span></div>

                <div class="container-login100-form-btn" style="margin-top: 50px;">
                  <button class="login100-form-btn" (click)="login()">
                      connexion
                  </button>
              </div>
            <div style="width: 100%;display: flex; justify-content: flex-end; align-items: flex-end;">
              <div class="" (click)="emitDataEmail()" style="cursor:pointer; font-size: medium; margin-top: 15px; margin-right: 20px;">Récuperer le mot de passe?</div>
            </div>
        </form>
      </div>
    </div>
  </div>
</div>


<ng-template #actionEmail>
  <div class="modal-header" style="padding-left: 15px; padding-right: 15px;">
    <h4 class="modal-title" style="margin-top: 12px;margin-bottom: 12px;">Récupération de mot de passe</h4>
    <div  class="close" aria-label="Close" (click)="resetField()">
      <span aria-hidden="false">&times;</span>
    </div>
  </div>
  <div class="modal-body" style="padding-left: 15px; padding-right: 15px;">
    <div class="form-group" style="margin-top: 20px;">
      <label>Email :</label>
      <input style="margin-top: 15px;" type="text" class="form-control" name="emails" [(ngModel)]="emailpassword">
    </div>
    <center style="margin-bottom: 12px;"><div *ngIf="messageemailinvalideOrDejaInBase !== ''">
      <h5 style="color: red;">{{messageemailinvalideOrDejaInBase}}</h5>
    </div></center>
  </div>
  <div class="modal-footer" style="margin: 15px;">
    <button type="button" class="" style="background-color: #1da1f2; color: white; margin-top: 12px; padding: 15px; border-radius: 6px;" (click)="envoieMailPassword()"> <i class="fa fa-check-circle" aria-hidden="true"></i> Récuperer</button>
  </div>
</ng-template>
