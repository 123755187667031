<div class="hold-transition sidebar-mini layout-fixed">
  <div class="wrapper">

  <!-- Navbar -->
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#"><i class="fas fa-bars"></i></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#"><h4 class="m-0 text-dark">{{title}}</h4></a>
      </li>


    </ul>

    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <a (click)="sign_out()" class="nav-link">
          <i class="fas fa-power-off"></i> Déconnexion
        </a>
      </li>
    </ul>
  </nav>
  <!-- /.navbar -->

  <!-- Main Sidebar Container -->
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <a (click)="toPage('home')" class="brand-link">
      <img src="../../../../assets/images/poubelle.jpg" alt="app collect logo" class="brand-image img-circle elevation-3"
           style="opacity: .8">
      <span class="brand-text font-weight-light" style="font-size: medium">App collect</span>
    </a>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user panel (optional) -->
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="info">
          <a class="d-block">{{entrepriseName}}</a>
        </div>
      </div>

      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">

          <li class="nav-item">
            <a class="nav-link" (click)="toPage('chauffeur')">
              <i class="fas fa-user-circle nav-icon"></i>
              <p>
                Chauffeurs
              </p>
            </a>
          </li>

          <li class="nav-item">
            <a (click)="toPage('ripeur')" class="nav-link">
              <i class="fas fa-user-circle nav-icon"></i>
              <p>
                Ripeurs
              </p>
            </a>
          </li>

          <li class="nav-item">
            <a (click)="toPage('circuit')" class="nav-link">
              <i class="fas fa-road nav-icon"></i>
              <p>
                Circuits
              </p>
            </a>
          </li>

          <li class="nav-item">
            <a (click)="toPage('bom')" class="nav-link">
              <i class="fas fa-car nav-icon"></i>
              <p>
                BOM
              </p>
            </a>
          </li>

          <li class="nav-item">
            <a (click)="toPage('anomalie')" class="nav-link">
              <i class="fas fa-exclamation-circle nav-icon"></i>
              <p>
                Liste Anomalies
              </p>
            </a>
          </li>

          <li class="nav-item">
            <a (click)="toPage('anomalie_bac')" class="nav-link">
              <i class="fas fa-exclamation-circle nav-icon"></i>
              <p>
                Rapport Anomalies
              </p>
            </a>
          </li>
          <li class="nav-item">
            <a (click)="toPage('rapport_j')" class="nav-link">
              <i class="fas fa-check-circle nav-icon"></i>
              <p>
                Rapport journalier
              </p>
            </a>
          </li>



          <li class="nav-item">
            <a (click)="toPage('pointage')" class="nav-link">
              <i class="fas fa-check nav-icon"></i>
              <p>
                Pointage
              </p>
            </a>
          </li>


          <li class="nav-item">
            <a (click)="toPage('collect')" class="nav-link">
              <i class="fas fa-map-marker nav-icon"></i>
              <p>
                Rapport Collecte
              </p>
            </a>
          </li>

          <li class="nav-item">
            <a (click)="toPage('affectation')" class="nav-link">
              <i class="fas fa-bookmark nav-icon"></i>
              <p>
                Rapport d'affectation
              </p>
            </a>
          </li>


          <li class="nav-item">
            <a (click)="toPage('tonnage')" class="nav-link">
              <i class="fas fa-weight-hanging nav-icon"></i>
              <p>
                Tonnage
              </p>
            </a>
          </li>

          <li class="nav-item">
            <a (click)="toPage('vidage')" class="nav-link">
              <img src="../../../../assets/images/dump.png" style="width: 25px; height: 25px; margin-right: 10px; object-fit: cover;"/>
              <p>
                Vidage
              </p>
            </a>
          </li>

          <li class="nav-item">
            <a (click)="toPage('rapportleveebac')" class="nav-link">
              <img src="../../../../assets/images/dump.png" style="width: 25px; height: 25px; margin-right: 10px; object-fit: cover;"/>
              <p>
                Rapport levée bac
              </p>
            </a>
          </li>

          <li class="nav-item" *ngIf="entrepriseType == '1'">
            <a (click)="toPage('entreprise')" class="nav-link">
              <i class="fas fa-circle nav-icon"></i>
              <p>
                Entreprise
              </p>
            </a>
          </li>

        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>

  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
<!--    <div class="content-header">-->
<!--      <div class="container-fluid">-->
<!--        <div class="row mb-2">-->
<!--          <div class="col-sm-6">-->
<!--            <h1 class="m-0 text-dark">{{title}}</h1>-->
<!--          </div>&lt;!&ndash; /.col &ndash;&gt;-->
<!--          <div class="col-sm-6">-->
<!--          </div>&lt;!&ndash; /.col &ndash;&gt;-->
<!--        </div>&lt;!&ndash; /.row &ndash;&gt;-->
<!--      </div>&lt;!&ndash; /.container-fluid &ndash;&gt;-->
<!--    </div>-->
    <!-- /.content-header -->

    <!-- Main content -->
    <section class="content" [ngSwitch]="page">
    <div class="container-fluid" *ngSwitchCase="'accueil'">
        <div class="row content-header">
          <div class="col-lg-3 col-6 ">
            <div class="small-box bg-info">
              <div class="inner">
                <h3>{{countDriver}}</h3>

                <p>Chauffeurs</p>
              </div>
              <div class="icon">
                <i class="ion ion-bag"></i>
              </div>
              <a (click)="toPage('chauffeur')" class="small-box-footer">Détail <i class="fas fa-arrow-circle-right"></i></a>
            </div>
          </div>

          <div class="col-lg-3 col-6">
            <div class="small-box bg-success">
              <div class="inner">
                <h3>{{countRipeur}}</h3>

                <p>Ripeurs</p>
              </div>
              <div class="icon">
                <i class="ion ion-stats-bars"></i>
              </div>
              <a (click)="toPage('ripeur')" class="small-box-footer">Détail <i class="fas fa-arrow-circle-right"></i></a>
            </div>
          </div>

          <div class="col-lg-3 col-6">
            <div class="small-box bg-warning">
              <div class="inner">
                <h3>{{countCircuit}}</h3>

                <p>Circuits</p>
              </div>
              <div class="icon">
                <i class="ion ion-stats-bars"></i>
              </div>
              <a (click)="toPage('circuit')" class="small-box-footer">Détail <i class="fas fa-arrow-circle-right"></i></a>
            </div>
          </div>

          <div class="col-lg-3 col-6">
            <div class="small-box bg-danger">
              <div class="inner">
                <h3>{{countBom}}</h3>

                <p>BOM</p>
              </div>
              <div class="icon">
                <i class="ion ion-pie-graph"></i>
              </div>
              <a (click)="toPage('bom')" class="small-box-footer">Détail <i class="fas fa-arrow-circle-right"></i></a>
            </div>
          </div>
        </div>
        <div class="row">
        </div>
      </div>


    <div class="card" *ngSwitchCase="'chauffeur'">
       <app-driver></app-driver>
    </div>
    <div class="card" *ngSwitchCase="'ripeur'">
        <app-ripeur></app-ripeur>
    </div>
    <div class="card" *ngSwitchCase="'circuit'">
        <app-circuit></app-circuit>
    </div>
    <div class="card" *ngSwitchCase="'bom'">
        <app-bom></app-bom>
    </div>
    <div class="card" *ngSwitchCase="'anomalie'">
        <app-anomaly></app-anomaly>
    </div>
    <div class="card" *ngSwitchCase="'anomalie_bac'">
         <app-anomaly-bac></app-anomaly-bac>
    </div>
    <div class="card" *ngSwitchCase="'rapport'">
        <app-report></app-report>
    </div>
    <div class="card" *ngSwitchCase="'collect'">
        <app-collect></app-collect>
    </div>
      <div class="card" *ngSwitchCase="'affectation'">
        <app-affectation></app-affectation>
      </div>
      <div class="card" *ngSwitchCase="'tonnage'">
        <app-tonnage></app-tonnage>
      </div>
    <div class="card" *ngSwitchCase="'entreprise'">
        <app-entreprise></app-entreprise>
    </div>
    <div class="card" *ngSwitchCase="'pointage'">
        <app-pointage></app-pointage>
    </div>
    <div class="card" *ngSwitchCase="'rapport_j'">
        <app-rapportjournalier></app-rapportjournalier>
    </div>
      <div class="card" *ngSwitchCase="'vidage'">
        <app-vidage></app-vidage>
      </div>
      <div class="card" *ngSwitchCase="'rapportleveebac'">
        <app-rapportleveebac></app-rapportleveebac>
      </div>

    </section>
    <!-- /.content -->
  </div>
  <!-- /.content-wrapper -->
  <footer class="main-footer">
  </footer>

  <!-- Control Sidebar -->
  <aside class="control-sidebar control-sidebar-dark">
    <!-- Control sidebar content goes here -->
  </aside>
  <!-- /.control-sidebar -->
</div>
</div>

